<!--
 * @Author: wangwei
 * @Date: 2020-12-28 16:06:28
 * @LastEditTime: 2023-02-16 14:31:37
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: 订单详情
 * @FilePath: /java-pc/src/views/member/order/List.vue
-->
<template>
    <div class="sld_order_detail" v-if="Object.keys(order_info.data).length > 0">
        <el-breadcrumb
            separator-class="el-icon-arrow-right"
            class="breadcrumb"
            style="line-height: 40px; font-size: 10px"
        >
            <el-breadcrumb-item :to="{ path: '/index' }">{{ L["首页"] }}</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/member/index' }">{{ L["个人中心"] }}</el-breadcrumb-item>
            <el-breadcrumb-item>{{ L["订单详情"] }}</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="container">
            <div class="top_info flex_row_start_center">
                <div class="top_info_state flex_column_center_center">
                    <p class="state">
                        {{ formatOrderState(order_info.data.orderState) }}
                    </p>
                    <block
                        v-if="
                            order_info.data.orderApprovalInfo &&
                            order_info.data.orderApprovalInfo.approvalRouteList.length == 0
                        "
                    >
                        <p
                            v-if="
                                (order_info.data.orderState == 0 && order_info.data.payState == 0) ||
                                (order_info.data.orderState == 0 && order_info.data.payState == 1)
                            "
                            class="refuse_reason"
                        >
                            {{ order_info.data.refuseReason }}
                        </p>
                    </block>

                    <p
                        class="refuse_reason"
                        v-if="
                            order_info.data.orderType == 103 &&
                            order_info.data.orderSubState == 102 &&
                            !(order_info.data.presellInfo && order_info.data.presellInfo.isStartRemainPay)
                        "
                    >
                        {{ order_info.data.presellInfo.remainStartTime }}开始付尾款
                    </p>

                    <!-- <div v-if="order_info.data.orderState == 30" class="oprate_btn pointer"
            @click="confirmReceipt(order_info.data.orderSn)">{{L['确认收货']}}</div> -->
                    <template v-if="order_info.data.orderState == 10">
                        <div v-if="order_info.data.orderType != 2">
                            <div
                                v-if="order_info.data.orderState == 10 && order_info.data.paymentWay != 'ACCEPT'"
                                class="oprate_btn pointer"
                                @click="goPay(order_info.data.paymentNumber)"
                            >
                                {{ L["立即支付"] }}
                            </div>
                        </div>
                    </template>
                    <div
                        v-if="
                            order_info.data.orderState == 40 &&
                            order_info.data.evaluateState != 3 &&
                            order_info.data.memberId == memberInfo.data.memberId
                        "
                        class="oprate_btn pointer"
                        @click="remainEvaluated(order_info.data.orderSn)"
                    >
                        {{ L["评价"] }}
                    </div>
                    <p class="cancel flex_row_center_center" v-if="afsState">
                        <span
                            class="pointer"
                            v-if="
                                order_info.data.orderState == 10 && order_info.data.memberId == memberInfo.data.memberId
                            "
                            @click="showSelectReasonDialog(order_info.data.parentSn)"
                            >{{ L["取消订单"] }}</span
                        >
                        <span
                            class="pointer"
                            v-if="
                                (order_info.data.orderState == 10 || order_info.data.orderState == 20) &&
                                order_info.data.memberId == memberInfo.data.memberId
                            "
                            @click="editAddress(order_info.data.orderSn)"
                            >{{ L["修改地址"] }}</span
                        >
                        <span
                            class="pointer"
                            v-if="
                                (order_info.data.orderState == 0 ||
                                    (order_info.data.orderState == 40 && order_info.data.evaluateState == 3)) &&
                                order_info.data.memberId == memberInfo.data.memberId
                            "
                            @click="delOrder(order_info.data.orderSn)"
                            >{{ L["删除订单"] }}</span
                        >
                    </p>
                </div>
                <!-- 正常订单 -->
                <div v-if="order_info.data.orderState != 0" class="top_info_progress flex_row_center_center">
                    <div class="progress_item">
                        <p>{{ L["提交订单"] }}</p>
                        <div class="progress flex_row_center_center">
                            <span :class="{ active: order_info.data.orderState >= 10 }">1</span>
                            <div class="progress_line_o">
                                <div
                                    :class="{
                                        content: true,
                                        active: order_info.data.orderState >= 10
                                    }"
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div class="progress_item">
                        <p>{{ L["支付订单"] }}</p>
                        <div class="progress flex_row_center_center">
                            <span :class="{ active: order_info.data.orderState >= 20 }">2</span>
                            <div class="progress_line_o">
                                <div
                                    :class="{
                                        content: true,
                                        active: order_info.data.orderState >= 20
                                    }"
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div class="progress_item">
                        <p>{{ L["商家发货"] }}</p>
                        <div class="progress flex_row_center_center">
                            <span :class="{ active: order_info.data.orderState >= 30 }">3</span>
                            <div class="progress_line_o">
                                <div
                                    :class="{
                                        content: true,
                                        active: order_info.data.orderState >= 30
                                    }"
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div class="progress_item">
                        <p>{{ L["确认收货"] }}</p>
                        <div class="progress flex_row_center_center">
                            <span :class="{ active: order_info.data.orderState >= 40 }">4</span>
                            <!--<div class="progress_line_o">
                <div
                  :class="{content:true,active:order_info.data.orderState>=40}">
                </div>
              </div>-->
                        </div>
                    </div>
                    <!--<div class="progress_item">
            <p>{{L['评价']}}</p>
            <div class="progress flex_row_center_center">
              <span :class="{active:order_info.data.evaluateState==3}">5</span>
            </div>
          </div>-->
                </div>
                <!-- 交易关闭订单 -->
                <div v-if="order_info.data.orderState == 0" class="top_info_progress flex_row_center_center">
                    <div class="progress_item">
                        <p>{{ L["提交订单"] }}</p>
                        <div class="progress flex_row_center_center">
                            <span :class="{ active: true }">1</span>
                            <div class="progress_line">
                                <div
                                    :class="{
                                        content: true,
                                        active: order_info.data.orderLogs && order_info.data.orderLogs.length >= 1,
                                        current: order_info.data.orderLogs && order_info.data.orderLogs.length == 1
                                    }"
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div class="progress_item">
                        <p>{{ L["交易关闭"] }}</p>
                        <div class="progress flex_row_start_center">
                            <span class="active">2</span>
                        </div>
                    </div>
                </div>
                <!-- 审核订单 start-->
                <div
                    style="width: 100%"
                    v-if="
                        order_info.data.orderApprovalInfo &&
                        order_info.data.orderApprovalInfo.approvalState == 0 &&
                        order_info.data.orderApprovalInfo.approvalRouteList.length > 0
                    "
                >
                    <div
                        v-if="
                            order_info.data.orderApprovalInfo.approvalState == 0 ||
                            order_info.data.orderApprovalInfo.approvalRouteList
                        "
                        class="top_info_progress flex_row_center_center"
                    >
                        <div class="progress_item">
                            <p>{{ L["提交订单"] }}</p>
                            <p>{{ order_info.data.createTime }}</p>
                            <div class="progress flex_row_center_center" style="margin-top: 6px">
                                <span
                                    :class="{
                                        active: order_info.data.orderLogs && order_info.data.orderLogs.length >= 1
                                    }"
                                    >1</span
                                >
                                <div class="progress_line">
                                    <div
                                        :class="{
                                            content: true,
                                            active:
                                                order_info.data.orderApprovalInfo.approvalRouteList &&
                                                order_info.data.orderApprovalInfo.approvalRouteList.length >= 1,
                                            current:
                                                order_info.data.orderApprovalInfo.approvalRouteList &&
                                                order_info.data.orderApprovalInfo.approvalRouteList.length > 1
                                        }"
                                    ></div>
                                </div>
                            </div>
                        </div>
                        <div class="progress_item">
                            <p>
                                {{
                                    order_info.data.orderApprovalInfo.approvalRouteList.length > 0
                                        ? order_info.data.orderApprovalInfo.approvalRouteList[0].memberName
                                        : "--"
                                }}
                            </p>
                            <div class="progress flex_row_start_center">
                                <span
                                    :class="{
                                        active: order_info.data.orderApprovalInfo.approvalRouteList.length >= 1,
                                        current:
                                            order_info.data.orderApprovalInfo.approvalRouteList &&
                                            order_info.data.orderApprovalInfo.approvalRouteList.length > 1
                                    }"
                                    >2</span
                                >
                                <div class="progress_line">
                                    <div
                                        :class="{
                                            content: true,
                                            active:
                                                order_info.data.orderApprovalInfo.approvalStat &&
                                                order_info.data.orderApprovalInfo.approvalState >= 0,
                                            current:
                                                order_info.data.orderApprovalInfo.approvalStat &&
                                                order_info.data.orderApprovalInfo.approvalState != 2
                                        }"
                                    ></div>
                                </div>
                            </div>
                        </div>
                        <div class="progress_item">
                            <p>审核完成</p>
                            <div class="progress flex_row_start_center">
                                <span
                                    :class="{
                                        active:
                                            order_info.data.orderApprovalInfo.approvalStat &&
                                            order_info.data.orderApprovalInfo.approvalState == 2
                                    }"
                                    >3</span
                                >
                            </div>
                        </div>
                    </div>
                    <div class="examine_info">审核失败原因：{{ order_info.data.orderApprovalInfo.reason }}</div>
                </div>
                <!-- 审核订单 end-->
            </div>
            <div class="logistics_info_box">
                <!--v-if="order_info.data.orderState == 30 ||order_info.data.orderState == 40" -->
                <div class="logistics_info_nav flex">
                    <div
                        :class="{ active: logisticsIndex == index }"
                        v-for="(item, index) in logistics_info.data"
                        :key="index"
                        @click="handleLogistics(item, index)"
                    >
                        物流{{ index + 1 }}
                    </div>
                </div>
                <div v-for="(item, index) in logistics_info.data" :key="index">
                    <div class="logistics_info flex_row_start_center" v-show="logisticsIndex == index">
                        <div class="logistics_info_left flex_row_center_center">
                            <!-- <div class="image flex_row_center_center">
                <img :src='logistics_info.data.goodsImage' alt />
                <coverImage :src='logistics_info.data.goodsImage' width="64" height="64"></coverImage>
              </div> -->
                            <div class="info">
                                <!-- <p>{{L['送货方式']}}：{{logistics_info.data.type==1?'无需物流':'快递'}}</p> -->
                                <p>{{ L["物流公司"] }}：{{ item.logisticsCompany }}</p>
                                <p>{{ L["物流单号"] }}：{{ item.trackingNumber }}</p>
                                <p>包含商品：{{ item.sendNum }}</p>
                            </div>
                        </div>
                        <div v-if="logisticsLog.data" class="logistics_info_right">
                            <div class="right_container">
                                <img v-if="Object.keys(logisticsLog.data).length == 0" :src="express_empty" alt />
                                <p v-if="Object.keys(logisticsLog.data).length == 0">
                                    {{ L["暂无物流信息"] }}
                                </p>
                                <el-timeline v-if="logisticsLog.data && Object.keys(logisticsLog.data).length > 0">
                                    <el-timeline-item
                                        v-for="(item, index) in logisticsLog.data"
                                        :key="index"
                                        :timestamp="item.acceptStation"
                                        >{{ item.acceptTime }}</el-timeline-item
                                    >
                                </el-timeline>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="order_info">
                <!-- 退款信息 -->
                <div class="refund_info" v-if="isRefund && order_info.data.returnMoneyAmount">
                    <p>退款信息</p>
                    <!-- <div>
            <span>下单数量：</span>
            <span>{{ productNum }} 吨</span>
          </div>
          <div>
            <span>修改数量：</span>
            <span>{{ enteringNunnage }} 吨</span>
          </div> -->
                    <div>
                        <span>退款金额：</span>
                        <span>￥ {{ order_info.data.returnMoneyAmount }}</span>
                    </div>
                    <div>退款已处理完成，实际退款到账时间因支付方式不同可能稍有延迟，请耐心等待。</div>
                </div>
                <!-- 订单信息 -->
                <p>{{ L["订单信息"] }}</p>
                <div class="order_info_item flex">
                    <div>
                        <span>{{ L["订单号"] }}：</span>
                        {{ order_info.data.memberOrderCode }}
                    </div>
                    <div>
                        <span>{{ L["发票抬头"] }}：</span>
                        {{ order_info.data.invoice ? order_info.data.invoice.invoiceTitle : "--" }}
                    </div>
                </div>

                <div class="order_info_item flex">
                    <div>
                        <span>{{ L["卖家"] }}：</span>
                        {{ order_info.data.seller }}
                    </div>
                    <div>
                        <span>销售合同：</span>
                        <span class="tx" @click="handleCheck(order_info.data)"> 查看合同</span>
                        <!--<span>暂无</span>-->
                    </div>
                </div>
                <div class="order_info_item">
                    <div
                        class="overflow_ellipsis_two"
                        :title="order_info.data.receiverAreaInfo + order_info.data.receiverAddress"
                    >
                        <span>{{ L["收货地址"] }}：</span>
                        {{ order_info.data.receiverAreaInfo }}
                        {{ order_info.data.receiverAddress }}
                    </div>
                </div>
                <div class="order_info_item">
                    <div>
                        <span>{{ L["收货人信息"] }}：</span>
                        {{ order_info.data.receiverName }}
                        {{ order_info.data.receiverMobile }}
                    </div>
                </div>
                <div class="order_info_item" v-if="order_info.data.paymentNumber">
                    <div>
                        <span>{{ L["交易流水号"] }}：</span>
                        {{ order_info.data.paymentNumber }}
                    </div>
                </div>
                <div
                    class="order_info_item"
                    v-if="
                        order_info.data.orderBelongs == 2 &&
                        order_info.data.orderPayType == 2 &&
                        !order_info.data.paymentTime
                    "
                >
                    <div>
                        <span>付款时间：</span>
                        {{ order_info.data.arriveDate }}
                    </div>
                </div>
                <div class="order_info_item" v-if="order_info.data.paymentTime">
                    <div>
                        <span>付款时间：</span>
                        {{ order_info.data.paymentTime }}
                    </div>
                </div>
                <div class="order_info_item">
                    <div>
                        <span>支付方式：</span>
                        {{ order_info.data.orderPayTypeName }}
                    </div>
                </div>
                <div class="order_info_item" v-if="order_info.data.orderPayType != 3">
                    <div>
                        <span>付款方式：</span>
                        {{ order_info.data.paymentWayName ? order_info.data.paymentWayName : "暂无" }}
                    </div>
                </div>
                <div class="order_info_item" v-if="order_info.data.paymentWay == 'ACCEPT'">
                    <div>
                        <span>票据号码：</span>
                        {{ getChengduiInfo(order_info.data.memberOrderCode) || billCodeVal }}
                    </div>
                </div>
                <div class="order_info_item" v-if="order_info.data.paymentWay == 'ACCEPT'">
                    <div>
                        <span>票据金额：</span>
                        {{ billAmountVal }}
                    </div>
                </div>
                <!-- <div class="order_info_item flex" style="display: flex;flex-direction: column;" v-if="order_info.data.orderLogs && order_info.data.orderLogs.length > 0">
          <div v-for="(item,index) in order_info.data.orderLogs" :key="index">
            <span>{{item.orderStateLog == 10 ? '创建时间：' : item.orderStateLog == 20 ? '付款时间：' :
									item.orderStateLog == 30 ? '发货时间：' : item.orderStateLog == 40 ? '完成时间：'
									:item.orderStateLog == 102?'定金支付时间：':'取消时间：'}}</span>
            {{item.logTime}}
          </div>
        </div> -->

                <!-- <div v-if="order_info.data.orderReserveList.length">
          <div class="order_info_item" v-for="(item,index) in order_info.data.orderReserveList"
            v-show="item.reserveValue">
            <div>
              <span>{{item.reserveName}}：</span>
              {{item.reserveValue}}
            </div>
          </div>
        </div> -->
            </div>
            <div class="order_mark">
                <p>
                    {{ order_info.data.orderState == 0 && order_info.data.payState == 1 ? "订单取消备注" : "订单备注" }}
                </p>
                <div class="order_mark_item" v-if="order_info.data.orderState == 0 && order_info.data.payState == 1">
                    <span>{{ order_info.data.refuseRemark ? order_info.data.refuseRemark : "--" }}</span>
                </div>
                <div class="order_mark_item" v-else>
                    <span>{{ order_info.data.orderRemark ? order_info.data.orderRemark : "--" }}</span>
                </div>
            </div>
            <div class="shop_info">
                <p>{{ L["商品信息"] }}</p>
                <div class="shop_title flex">
                    <div class="detail">{{ L["商品详情"] }}</div>
                    <div class="detail">大石sku</div>
                    <div class="price">{{ L["单价"] }}</div>
                    <div class="num">{{ L["数量"] }}</div>
                    <div class="detail">总价</div>
                    <div class="detail">质保期</div>
                    <div class="detail">商品状态</div>
                    <div class="oprate">
                        {{ L["操作"] }}
                    </div>
                </div>
                <div v-for="(goodItem, index) in order_info.data.goodsDetails" :key="index" class="good_item flex">
                    <div class="good_info flex">
                        <div class="image flex_row_center_center">
                            <!-- <img :src="goodItem.productImage" alt /> -->
                            <coverImage :src="goodItem.goodsImage" width="64" height="64"></coverImage>
                        </div>
                        <div class="good_info_con">
                            <router-link
                                :to="`/goods/detail?productId=${goodItem.skuCode}`"
                                class="name"
                                target="_blank"
                            >
                                <span class="gift" v-if="goodItem.isGift == 1">赠品</span>{{ goodItem.goodsNameJoin }}
                            </router-link>
                            <!-- <div class="name">{{goodItem.goodsName}}</div> -->
                            <!-- <div class="spec">{{goodItem.specValues}}</div> -->
                        </div>
                    </div>
                    <div class="price flex_row_center_center">{{ goodItem.skuCode }}</div>
                    <div class="price flex_row_center_center">
                        {{ goodItem.goodsPrice }}
                    </div>
                    <div class="num flex_row_center_center">*{{ goodItem.goodsNum }}</div>
                    <div class="price flex_row_center_center">
                        {{ goodItem.goodsSumPrice }}
                    </div>
                    <div class="price flex_row_center_center">
                        {{ goodItem.qualityDay }}
                    </div>
                    <div class="price flex_row_center_center">
                        {{
                            goodItem.state == 20
                                ? "待发货"
                                : goodItem.state == 30
                                ? "待收货"
                                : goodItem.state == 40
                                ? "已完成"
                                : goodItem.state == 50
                                ? "已关闭"
                                : goodItem.state == 1
                                ? "待审批"
                                : goodItem.state == 20
                                ? "待发货"
                                : goodItem.state == 0
                                ? "已取消"
                                : goodItem.state == 10
                                ? "待付款"
                                : ""
                        }}
                    </div>
                    <div class="oprate flex_column_around_center">
                        <!-- * 商品状态afterState:0 = 未售后，1、售后中，2、已完成;3、已拒绝 -->
                        <!-- 退款中 可查看退款详情  售后按钮，100-退款（商家未发货），200-退款（商家发货,买家未收货），300-申请售后，401-退款中，402-退款完成,403-换货中，404-换货完成	301,退款失败-平台审核失败-->
                        <span
                            class="refund_btn pointer sp1"
                            v-if="
                                (goodItem.afsButton == 401 ||
                                    goodItem.afsButton == 402 ||
                                    goodItem.afsButton == 403 ||
                                    goodItem.afsButton == 404 ||
                                    goodItem.afsButton == 301) &&
                                goodItem.isGift == 0
                            "
                            @click.stop="goRefundDetail(goodItem.afsSn, goodItem.afsButton)"
                            >{{ goodItem.afsButtonValue }}</span
                        >

                        <!-- 退款 可申请退款-->
                        <span
                            class="refund_btn pointer sp2"
                            v-if="
                                (goodItem.afsButton == 100 || goodItem.afsButton == 200 || goodItem.afsButton == 300) &&
                                goodItem.isGift == 0 &&
                                !order_info.data.returnMoneyAmount
                            "
                            @click.stop="selectService(goodItem.skuCode, order_info.data.orderSn)"
                            >{{ goodItem.afsButtonValue }}</span
                        >

                        <!-- 申请换货 -->
                        <span
                            class="refund_btn pointer sp3"
                            v-if="
                                (goodItem.afsButton == 100 ||
                                    goodItem.afsButton == 200 ||
                                    goodItem.afsButton == 300 ||
                                    goodItem.afsButton == 404) &&
                                goodItem.isGift == 0 &&
                                (order_info.data.orderState == 30 || order_info.data.orderState == 40) &&
                                !order_info.data.returnMoneyAmount
                            "
                            @click.stop="selectBarter(goodItem.skuCode, order_info.data.orderSn)"
                            >申请换货</span
                        >
                        <!-- 申请换货 -->
                        <span
                            class="refund_btn pointer sp3"
                            @click.stop="goRefundDetail(order_info.data.memberOrderCode, goodItem.afsButton)"
                            v-if="(goodItem.afsButton == 406 || goodItem.afsButton == 407) && goodItem.isGift == 0"
                            >{{ goodItem.afsButtonValue }}</span
                        >

                        <span
                            class="refund_btn pointer sp3"
                            v-if="
                                (goodItem.afsButton == 406 || goodItem.afsButton == 407) &&
                                goodItem.isGift == 0 &&
                                (order_info.data.orderState == 30 || order_info.data.orderState == 40)
                            "
                            @click.stop="selectBarter(goodItem.skuCode, order_info.data.orderSn)"
                            >再次申请换货</span
                        >
                        <span
                            class="refund_btn pointer sp1"
                            v-if="goodItem.afterState == 1"
                            @click.stop="goRefundDetail(goodItem.afterOrderCode, goodItem.afterState)"
                            >售后中</span
                        >
                        <span
                            class="refund_btn pointer sp1"
                            v-if="goodItem.afterState == 2"
                            @click.stop="goRefundDetail(goodItem.afterOrderCode, goodItem.afterState)"
                            >售后完成</span
                        >
                        <span
                            class="refund_btn pointer sp1"
                            v-if="goodItem.afterState == 4"
                            @click.stop="afterSales(goodItem.skuCode, order_info.data.memberOrderCode, goodItem.state)"
                            >部分售后</span
                        >
                        <!--<span
              class="refund_btn pointer sp1"
              v-if="goodItem.afterState == 3"
              @click.stop="
                selectService(goodItem.skuCode, order_info.data.memberOrderCode)
              "
              >已拒绝</span
            >-->
                        <span
                            class="refund_btn pointer sp2"
                            v-if="
                                (goodItem.afsButton == 100 || goodItem.afsButton == 200 || goodItem.afsButton == 300) &&
                                goodItem.isGift == 0 &&
                                isRefund &&
                                order_info.data.returnMoneyAmount
                            "
                            >已退款</span
                        >

                        <!-- 申请换货 -->
                        <span class="refund_btn pointer" style="cursor: default" v-if="goodItem.afsButton == 405"
                            >已过售后服务期</span
                        >

                        <!-- 赠品不能申请售后 -->
                        <!--<span
              class="refund_btn"
              v-if="
                !(goodItem.isApplyAfter || goodItem.isConfirmReceive) &&
                (goodItem.isGift == 1 ||
                  order_info.data.paymentWay == 'OFFLINE')
              "
              >--</span
            >-->
                        <span
                            class="refund_btn pointer sp3"
                            v-if="goodItem.isConfirmReceive"
                            @click.stop="
                                confirmReceipt(order_info.data.memberOrderCode, goodItem.skuCode, goodItem.spuCode)
                            "
                            >确认收货</span
                        >
                        <span
                            class="refund_btn pointer sp1"
                            v-if="goodItem.isRollBackAfter"
                            @click.stop="CancelAfterSale(goodItem.afterOrderCode, goodItem.skuCode)"
                            >撤销售后</span
                        >
                        <span
                            class="refund_btn pointer sp1"
                            v-if="goodItem.isApplyAfter"
                            @click.stop="afterSales(goodItem.skuCode, order_info.data.memberOrderCode, goodItem.state)"
                            >申请售后</span
                        >
                        <!--<span class="refund_btn" v-if="(!goodItem.isConfirmReceive)&&(!goodItem.isApplyAfter)">--</span>-->
                    </div>
                </div>
            </div>
            <div class="settle_info">
                <p>
                    {{ L["商品金额"] }}：
                    <span
                        >¥{{
                            order_info.data.goodsDetails.reduce((sum, item) => sum + item.goodsSumPrice * 1000, 0) /
                            1000
                        }}</span
                    >
                </p>
                <!-- <p>
          {{L['满优惠']}}：
          <span>-¥{{order_info.data.fullDiscountAmount}}</span>
        </p> -->
                <p>
                    {{ L["运费金额"] }}：
                    <span>¥{{ order_info.data.freightAmount }}</span>
                </p>
                <p v-if="order_info.data.storeVoucherAmount > 0">
                    {{ L["店铺优惠券"] }}：
                    <span>-¥{{ order_info.data.storeVoucherAmount }}</span>
                </p>
                <p v-if="order_info.data.platformVoucherAmount > 0">
                    {{ L["平台优惠券"] }}：
                    <span>-¥{{ order_info.data.platformVoucherAmount }}</span>
                </p>
                <p v-if="order_info.data.integralCashAmount > 0">
                    {{ L["积分抵扣"] }}：
                    <span>-¥{{ order_info.data.integralCashAmount }}</span>
                </p>
                <p class="total">
                    订单金额：
                    <span
                        >¥{{
                            orderAmtShowContent(order_info.data.orderShouldSumAmount, order_info.data.orderSumAmount)
                        }}
                    </span>
                </p>
            </div>
        </div>
        <!-- 取消地址理由弹窗 start -->
        <el-dialog
            :title="L['取消订单理由']"
            v-model="cancel_order"
            customClass="select_reason_width"
            :before-close="handleClose"
            lock-sroll="false"
        >
            <div class="cancel_list_con">
                <div
                    v-for="(reasonItem, index) in cancel_list.data"
                    :key="index"
                    :class="{
                        reason_item: true,
                        flex_row_between_center: true,
                        active: current_reason_id == reasonItem.reasonId
                    }"
                    @click="selectReason(reasonItem.reasonId)"
                >
                    <span class="reason_text">{{ reasonItem.content }}</span>
                    <img :src="current_reason_id == reasonItem.reasonId ? selected : not_select" alt />
                </div>
            </div>
            <div class="confirm_cancel_btn" @click="confirmCancel()">
                {{ L["确定"] }}
            </div>
        </el-dialog>
        <!--  取消地址理由弹窗 end  -->
        <!-- 地址选择弹窗 start -->
        <el-dialog
            :title="L['更换地址']"
            v-model="show_select_address"
            customClass="select_address_width"
            :before-close="handleClose"
            lock-sroll="false"
        >
            <div class="out_stock_dialog address_con">
                <div
                    v-for="(item, index) in address_list.data"
                    :key="index"
                    @click="selectAddress(index)"
                    :class="{
                        address_item: true,
                        flex_column_start_start: true,
                        select: current_address_index == index
                    }"
                >
                    <span>{{ L["收货人"] }}：{{ item.memberName }}</span>
                    <span>{{ L["联系方式"] }}：{{ item.telMobile }}</span>
                    <span class="address_text">
                        <div>{{ L["收货地址"] }}：</div>
                        <div>{{ item.addressAll }} {{ item.detailAddress }}</div>
                    </span>
                    <img v-if="current_address_index == index" class="selected" :src="invoice_selected" alt />
                </div>
            </div>
            <div class="btn_con flex_row_center_center">
                <div class="confirm_cancel_btn" @click="confirmChangeAddress()">
                    {{ L["提交修改"] }}
                </div>
            </div>
        </el-dialog>
        <!-- 地址选择弹窗 end -->
        <!-- 大宗商品无售后弹窗start -->
        <el-dialog v-model="centerDialogVisible" title="提示" width="30%" align-center>
            <span>此商品不支持售后、换货服务，如有问题请联系客服。</span>
            <template #footer>
                <span class="dialog-footer">
                    <el-button type="primary" @click="centerDialogVisible = false"> 确定 </el-button>
                </span>
            </template>
        </el-dialog>

        <div class="payModalStyle">
            <el-dialog
                v-model="dialogVisible"
                :close-on-click-modal="false"
                :before-close="closeModal"
                top="13%"
                width="23%"
                title="选择售后类型"
            >
                <div>
                    <div class="invoice_cons flex">
                        <div
                            class="no_select_invoice flex_row_center_center"
                            :class="{ activeLt: index != 0 }"
                            v-for="(item, index) in AfterSaleTypeList.data"
                            :key="index"
                            @click="handleAfterSaleType(index)"
                        >
                            <img :src="selecteds" v-if="activeIndex == index && item.isShow" alt />
                            <img :src="not_selects" v-if="activeIndex != index && item.isShow" alt />
                            <span v-if="item.isShow">{{ item.label }}</span>
                        </div>
                    </div>
                </div>
                <template #footer>
                    <div style="display: flex; justify-content: center">
                        <div class="Clear" @click="closeModal">取消</div>
                        <div class="Submit2" @click="selectService(RouterList.skuCode, RouterList.orderSn)">确定</div>
                    </div>
                </template>
            </el-dialog>
        </div>
        <!-- 大宗商品无售后弹窗end -->
    </div>
</template>
<script>
import { reactive, getCurrentInstance, ref, onMounted, computed } from "vue";
import {
    elBreadcrumb,
    elBreadcrumbItem,
    ElMessage,
    ElDialog,
    ElMessageBox,
    ElTimeline,
    ElTimelineItem
} from "element-plus";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { platform } from "@/utils/config";
export default {
    name: "order-detail",
    components: {
        elBreadcrumb,
        elBreadcrumbItem,
        ElDialog,
        ElTimeline,
        ElTimelineItem
    },
    setup() {
        // const balance = require("../../assets/buy/balance.png");
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        const memberInfo = reactive({ data: store.state.memberInfo });
        const { proxy } = getCurrentInstance();
        const L = proxy.$getCurLanguage();
        const order_info = reactive({ data: {} });
        const express_empty = require("../../../assets/order/express_empty.png");
        const not_select = require("../../../assets/order/not_select.png");
        const selected = require("../../../assets/order/selected.png");
        const selecteds = require("../../../assets/buy/selected.png");
        const not_selects = require("../../../assets/buy/not_select.png");
        const activeIndex = ref(0);
        const RouterList = ref({});
        const invoice_selected = require("../../../assets/buy/invoice_selected.png");
        const cancel_list = reactive({ data: [] });
        const centerDialogVisible = ref(false); //大宗商品弹窗提醒
        const cancel_order = ref(false); //是否显示取消订单弹窗
        const isRefund = ref(false); //是否显示退款信息
        const productNum = ref(); //下单数量
        const enteringNunnage = ref(); //修改数量
        const current_reason_id = ref(); //记录当前已选择理由id
        const order_sn = ref(""); //记录当前操作的订单号
        const show_select_address = ref(false); //是否显示选择地址弹窗
        const address_list = reactive({ data: [] }); //地址列表
        const current_address_index = ref(0); //记录当前选择的地址
        const logistics_info = reactive({ data: [] }); //物流信息
        const logisticsLog = reactive({ data: {} }); //物流进度
        const logisticsCompany = ref(""); //物流公司
        const logisticsNumber = ref(""); //物流单号
        const mobilePhoneNumber = ref(""); //物流收货人电话
        const logisticsIndex = ref(0); //物流信息下标
        const afsState = ref(true);
        const billCodeVal = ref("");
        const billAmountVal = ref("");
        const dialogVisible = ref(false);
        // 使用 computed 创建计算属性
        const formatOrderState = computed(() => {
            return (value) => {
                switch (value) {
                    case 0:
                        return "已取消";
                    case 1:
                        return "待审批";
                    case 10:
                        return "待支付";
                    case 20:
                        return "待发货";
                    case 30:
                        return "待收货";
                    case 40:
                        return "已完成";
                    case 50:
                        return "已关闭";
                    default:
                        return "未知";
                }
            };
        });
        const AfterSaleTypeList = reactive({
            data: [
                //企业会员
                { label: "仅退款", isShow: true },
                { label: "退货退款", isShow: true },
                { label: "换货", isShow: true }
            ]
        });
        //选择支付方式
        const handleAfterSaleType = (e) => {
            activeIndex.value = e;
        };

        const orderAmtShowContent = (oldAmt, newAmt) => {
            if (oldAmt == newAmt) {
                return oldAmt;
            }
            return oldAmt + "/" + newAmt;
        };

        const getOrderDetail = () => {
            proxy
                .$get("api/shopp/member/order/detail", {
                    //原接口：v3/business/front/orderInfo/detail
                    memberOrderCode: route.query.orderSn
                })
                .then((res) => {
                    console.log(res);
                    if (res.code == 200) {
                        order_info.data = res.data;
                        console.log(order_info.data);
                        // if (order_info.data.orderState == 30 || order_info.data.orderState == 40) {
                        lookLogistics(order_info.data.memberOrderCode);
                        // }
                        // order_info.data.childOrdersVOS.map(child => {
                        //   child.orderProductListVOList.map(item => {
                        //     if (item.afsButton == 200 || item.afsButton == 201) {
                        //       afsState.value = false
                        //     }
                        //   })
                        // })
                        // for (let i = 0; i < order_info.data.childOrdersVOS.length; i++) {
                        //   for (let j = 0; j < order_info.data.childOrdersVOS[i].orderProductListVOList.length; j++) {
                        //     if(order_info.data.childOrdersVOS[i].orderProductListVOList[j].caregoryIdOne==12){
                        //       isRefund.value = true;
                        //       productNum.value= order_info.data.childOrdersVOS[i].orderProductListVOList[j].productNum;
                        //       enteringNunnage.value= order_info.data.childOrdersVOS[i].orderProductListVOList[j].enteringNunnage
                        //     }
                        //   }
                        // }
                    } else {
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {
                    //异常处理
                });
        };

        //查看承兑支付信息
        const getChengduiInfo = (memberOrderCode) => {
            let arr = [];
            let arr2 = [];
            proxy
                .$get("api/shopp/member/order/accept/pay/record", { memberOrderCode })
                .then((res) => {
                    if (res.code == 200) {
                        for (let i = 0; i < res.data.length; i++) {
                            arr.push(res.data[i].billCode);
                            arr2.push(res.data[i].billAmount);
                        }
                        billCodeVal.value = arr.join("/");
                        billAmountVal.value = arr2.join("/");
                    } else {
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {
                    //异常处理
                });
        };

        //查看电子合同
        const handleCheck = (e) => {
            if (!e.memberContractUrl) {
                proxy
                    .$get("api/shopp/member/order/url", {
                        orderCode: e.memberOrderCode,
                        flag: platform
                    })
                    .then((res) => {
                        //原接口：/v3/business/front/contract/getOrderContract
                        if (res.code == 200) {
                            // download(res.msg);
                            window.open(res.data);
                            // ElMessage.success("下载成功")
                        } else {
                            ElMessage(res.msg);
                        }
                    })
                    .catch(() => {
                        //异常处理
                    });
            } else {
                window.open(e.memberContractUrl);
            }
        };

        // 获取订单取消理由列表
        const getCancelList = () => {
            proxy
                .$get("api/shopp/after/reason/list", {
                    reasonType: 5
                })
                .then((res) => {
                    if (res.code == 200) {
                        cancel_list.data = res.data;
                    } else {
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {
                    //异常处理
                });
        };
        //选择理由
        const selectReason = (id) => {
            current_reason_id.value = id;
        };
        //显示选择理由弹窗
        const showSelectReasonDialog = (orderSn) => {
            if (
                !order_info.data.isRefundDeposit &&
                (order_info.data.orderType == 105 || order_info.data.orderType == 103) &&
                order_info.data.orderSubState == 102
            ) {
                ElMessageBox.confirm("取消该订单定金不予退还,确定取消?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    cancel_order.value = true;
                    order_sn.value = orderSn;
                });
            } else {
                cancel_order.value = true;
                order_sn.value = orderSn;
            }
        };
        const CancelAfterSale = (afterOrderCode, skuCode) => {
            ElMessageBox.confirm("是否撤销售后?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    proxy
                        .$get("api/shopp/order/after/rollbackSubmitAfter", {
                            afterOrderCode,
                            skuCode
                        })
                        .then((res) => {
                            if (res.code == 200) {
                                ElMessage.success("撤销售后成功");
                                getOrderDetail();
                            } else {
                                ElMessage(res.msg);
                            }
                        })
                        .catch(() => {
                            //异常处理
                        });
                })
                .catch(() => {});
        };
        //确认取消订单
        const confirmCancel = () => {
            if (!current_reason_id.value) {
                ElMessage.warning(L["请选择取消理由"]);
                return;
            }
            proxy
                .$post("v3/business/front/orderOperate/cancel", {
                    parentSn: order_sn.value,
                    reasonId: current_reason_id.value
                })
                .then((res) => {
                    if (res.state == 200) {
                        ElMessage.success(L["取消订单成功"]);
                        cancel_order.value = false;
                        getOrderDetail();
                    } else {
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {
                    //异常处理
                });
        };
        //删除订单
        const delOrder = (orderSn) => {
            ElMessageBox.confirm(L["确认删除该订单?"], L["提示"], {
                confirmButtonText: L["确定"],
                cancelButtonText: L["取消"],
                type: "warning"
            }).then(() => {
                proxy
                    .$post("v3/business/front/orderOperate/delete", {
                        orderSn
                    })
                    .then((res) => {
                        if (res.state == 200) {
                            ElMessage.success(L["删除订单成功"]);
                            router.go(-1);
                        } else {
                            ElMessage(res.msg);
                        }
                    });
            });
        };
        //确认收货
        const confirmReceipt = (memberOrderCode, skuCode, spuCode) => {
            console.log(11);
            ElMessageBox.confirm(L["确认收货?"], L["提示"], {
                confirmButtonText: L["确定"],
                cancelButtonText: L["取消"],
                type: "warning"
            })
                .then(() => {
                    proxy
                        .$post("api/shopp/member/order/singleGoodsReceived", {
                            memberOrderCode,
                            skuCode,
                            spuCode
                        })
                        .then((res) => {
                            if (res.code == 200) {
                                ElMessage.success(L["确认收货成功"]);
                                getOrderDetail();
                            } else {
                                ElMessage(res.msg);
                            }
                        })
                        .catch(() => {
                            //异常处理
                        });
                })
                .catch(() => {});
        };
        //选择地址
        const editAddress = (orderSn) => {
            show_select_address.value = true;
            order_sn.value = orderSn;
        };
        //获取地址列表
        const getAddressList = () => {
            let params = { uscUserId: store.state.memberInfo.uscUserId, pageSize: 1000 };
            proxy
                .$get("api/shopp/member/address/list", params)
                .then((res) => {
                    if (res.code == 200) {
                        address_list.data = res.data;
                    } else {
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {
                    //异常处理
                });
        };
        //切换地址
        const selectAddress = (index) => {
            current_address_index.value = index;
        };
        //确认修改地址
        const confirmChangeAddress = () => {
            proxy
                .$post("v3/business/front/orderOperate/updateAddress", {
                    orderSn: order_sn.value,
                    addressId: address_list.data[current_address_index.value].addressId
                })
                .then((res) => {
                    if (res.state == 200) {
                        ElMessage.success(L["切换地址成功"]);
                        show_select_address.value = false;
                        getOrderDetail();
                    } else {
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {
                    //异常处理
                });
        };

        // 物流信息切换
        const handleLogistics = (e, i) => {
            logisticsIndex.value = i;
            logisticsCompany.value = e.logisticsCompany;
            logisticsNumber.value = e.trackingNumber;
            mobilePhoneNumber.value = e.mobilePhoneNumber;
            logisticsTraces();
        };

        //显示查看物流信息
        const lookLogistics = (memberOrderCode) => {
            proxy
                .$get("api/shopp/member/order/logistics/details", {
                    //原接口：v3/business/front/logistics/order/getTrace
                    memberOrderCode
                })
                .then((res) => {
                    if (res.code == 200) {
                        logistics_info.data = res.data;
                        logisticsCompany.value = logistics_info.data[0].logisticsCompanyCode;
                        logisticsNumber.value = logistics_info.data[0].trackingNumber;
                        mobilePhoneNumber.value = logistics_info.data[0].mobilePhoneNumber;
                        logisticsTraces();
                    } else {
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {
                    //异常处理
                });
        };

        //物流轨迹
        const logisticsTraces = () => {
            proxy
                .$get("api/shopp/member/order/logistics/traces", {
                    //根据当前物流公司，物流单号查询物流进度
                    logisticsCompanyCode: logisticsCompany.value,
                    logisticsNumber: logisticsNumber.value,
                    mobilePhoneNumber: mobilePhoneNumber.value
                })
                .then((res) => {
                    if (res.code == 200) {
                        logisticsLog.data = res.data;
                    }
                });
        };

        //评价订单
        const remainEvaluated = (orderSn) => {
            router.push({
                path: "/member/order/evaluate",
                query: {
                    orderSn: orderSn
                }
            });
        };
        //立即支付
        const goPay = (orderSn) => {
            router.push({
                path: "/buy/pay",
                query: {
                    paySn: orderSn,
                    payFrom: 1
                }
            });
        };
        //选择售后服务
        const selectService = (skuCode, orderSn) => {
            console.log(skuCode, orderSn);
            // AfterSaleTypeList.data[1].isShow=true;
            //AfterSaleTypeList.data[2].isShow=true;
            // if(isRefund.value) {
            //  return centerDialogVisible.value = true
            // }
            // proxy.$get('v3/business/front/after/sale/apply/applyInfo', {
            //   orderSn,
            //   orderProductId
            // }).then(res => {
            //   if (res.state == 200) {
            console.log(activeIndex.value);

            router.push({
                path: "/member/order/refund/apply",
                query: {
                    orderState: order_info.data.orderState,
                    orderSn,
                    skuCode,
                    type: activeIndex.value == 1 ? "退货退款" : activeIndex.value == 2 ? "换货" : "仅退款"
                }
            });
            //   } else {
            //     ElMessage(res.msg)
            //   }
            // })
        };
        const afterSales = (skuCode, orderSn, state) => {
            // if(state==20){
            //    AfterSaleTypeList.data[1].isShow=false;
            //  AfterSaleTypeList.data[2].isShow=false;
            // }

            dialogVisible.value = true;
            RouterList.value = {
                orderSn,
                skuCode,
                orderState: order_info.data.orderState
                //state:goodItem.state
            };
        };

        //选择换货服务
        const selectBarter = (orderProductId, orderSn) => {
            if (isRefund.value) {
                return (centerDialogVisible.value = true);
            }
            router.push({
                path: "/member/order/refund/barter",
                query: {
                    orderState: order_info.data.orderState,
                    orderProductId
                }
            });
            // proxy.$get('v3/business/front/after/sale/apply/applyInfo', {
            //   orderSn,
            //   orderProductId
            // }).then(res => {
            //   if (res.state == 200) {
            //   } else {
            //     ElMessage(res.msg)
            //   }
            // })
        };

        //查看售后详情
        const goRefundDetail = (afterOrderCode, typ) => {
            //if (typ == 1 || typ == 2) {
            router.push({
                path: "/member/order/refund/detail",
                query: {
                    orderId: afterOrderCode,
                    orderState: order_info.data.orderState,
                    afterOrderCode
                }
            });
            //}
            //else {
            //  router.push({
            //    path: "/member/order/exchange/detail",
            //    query: {
            //      curTitleId: 3,
            //      orderId: order_info.data.orderSn,
            //      orderState: order_info.data.orderState,
            //      afsSn,
            //    },
            //  });
            //}
        };
        const closeModal = () => {
            dialogVisible.value = false;
            // AfterSaleTypeList.data[1].isShow=true;
            //AfterSaleTypeList.data[2].isShow=true;
        };
        onMounted(() => {
            getOrderDetail();
            getCancelList();
            getAddressList();
        });
        return {
            L,
            order_sn,
            order_info,
            express_empty,
            showSelectReasonDialog,
            cancel_list,
            current_reason_id,
            selectReason,
            cancel_order,
            not_select,
            selected,
            confirmCancel,
            delOrder,
            confirmReceipt,
            editAddress,
            confirmChangeAddress,
            selectAddress,
            handleLogistics,
            current_address_index,
            logisticsIndex,
            address_list,
            show_select_address,
            invoice_selected,
            logistics_info,
            logisticsLog,
            logisticsCompany,
            logisticsNumber,
            mobilePhoneNumber,
            remainEvaluated,
            selectService,
            goPay,
            goRefundDetail,
            afsState,
            selectBarter,
            handleCheck,
            getChengduiInfo,
            billCodeVal,
            billAmountVal,
            memberInfo,
            centerDialogVisible,
            isRefund,
            productNum,
            enteringNunnage,
            formatOrderState,
            AfterSaleTypeList,
            handleAfterSaleType,
            dialogVisible,
            selecteds,
            not_selects,
            activeIndex,
            RouterList,
            afterSales,
            CancelAfterSale,
            closeModal,
            orderAmtShowContent
        };
    }
};
</script>
<style lang="scss">
@import "../../../style/member/orderDetail.scss";
@import "../../../style/orderList.scss";

.Submit2 {
    background: #0e3177;
    width: 76px;
    height: 35px;
    border-radius: 4px;
    text-align: center;
    color: #fff;
    margin-left: 10px;
    line-height: 35px;
    font-size: 14px;
    cursor: pointer;
}

.Clear {
    width: 76px;
    height: 35px;
    border-radius: 4px;
    text-align: center;
    color: #5e5858;
    line-height: 35px;
    font-size: 14px;
    border: 1px solid #ccc;
    cursor: pointer;
}

.invoice_cons {
    height: 50px;
    display: flex;
    /*justify-content: center;*/
    justify-content: space-evenly;
    align-items: flex-end;

    .activeLt {
        margin-left: 20px;
    }

    .no_select_invoice {
        cursor: pointer;

        img {
            width: 18px;
            height: 18px;
        }

        span {
            font-size: 14px;
            line-height: 14px;
            margin-left: 10px;
        }

        .invoice_info {
            margin-left: 50px;
            border-left: 1px solid #dddddd;

            span {
                font-size: 14px;
                line-height: 14px;
                margin-left: 50px;
            }

            .choose {
                color: #257bfd;
                cursor: pointer;
            }
        }

        .block {
            margin-left: 10px;

            ::v-deep .el-date-editor.el-input {
                width: 160px;
            }

            .el-input__inner {
                padding-left: 40px;
            }
        }

        .date_box {
            margin-left: 10px;

            ::v-deep .el-date-editor.el-input {
                width: 160px;
            }
        }
    }
}

.invoice_con {
    height: 50px;
    display: flex;

    /*justify-content: center;*/
    /*justify-content: space-evenly;
    align-items: flex-end;*/
    .activeLt {
        margin-left: 20px;
    }

    .no_select_invoice {
        cursor: pointer;

        img {
            width: 18px;
            height: 18px;
        }

        span {
            font-size: 14px;
            line-height: 14px;
            margin-left: 10px;
        }

        .invoice_info {
            margin-left: 50px;
            border-left: 1px solid #dddddd;

            span {
                font-size: 14px;
                line-height: 14px;
                margin-left: 50px;
            }

            .choose {
                color: #257bfd;
                cursor: pointer;
            }
        }

        .block {
            margin-left: 10px;

            ::v-deep .el-date-editor.el-input {
                width: 160px;
            }

            .el-input__inner {
                padding-left: 40px;
            }
        }

        .date_box {
            margin-left: 10px;

            ::v-deep .el-date-editor.el-input {
                width: 160px;
            }
        }
    }
}
</style>
