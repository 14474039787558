<template>
    <div class="sld_login" v-if="isShow">
        <div class="sld_login_header">
            <div class="content">
                <router-link tag="a" class="l_logo" :to="`/index`">
                    <img class="img" :src="platform == 1 ? gongcaiLogo : jicaiLogo" :onerror="defaultImg" alt />
                </router-link>
                <div class="r_register_wrap">
                    {{ L["还没注册？"] }}
                    <a class="go_register_btn" href="javascript:void(0)" @click="goToPage('/register')">{{
                        L["去注册"]
                    }}</a>
                </div>
            </div>
        </div>
        <div class="sld_login_contents">
            <img class="bg" :src="platform == 1 ? gongcaiBg : jicaiBg" :onerror="defaultBgImg" alt />
            <div class="login">
                <div class="login_box">
                    <!-- <div class="login_type flex_row_center_center">
            <div class="flex_row_center_center" :class="{'login_type_p':loginTypeo==1}" @click="login_typeo(1)">
              个人登录
            </div>
            <div class="flex_row_center_center" :class="{'login_type_p':loginTypeo==2}" @click="login_typeo(2)">
              企业登录
            </div>
          </div> -->
                    <!-- <div class="qrLogin" @click="changeQrlog">
              <img class="qrLeft" :src="QRcodeLogin?idCode:qrCode">
              <img v-if="QRcodeLogin" style="width:110px;" class="qrRight" :src="idLogin">
              <img v-else class="qrRight" :src="qrLogin">
          </div> -->
                    <template v-if="!QRcodeLogin">
                        <div class="top">
                            <!-- <div :class="{item:true,active:loginType == 1}" @click="changeLoginType(1)">{{L['账号登录']}}</div> -->
                            <div :class="{ item: true, active: loginType == 2 }" @click="changeLoginType(2)">
                                {{ L["验证码登录"] }}
                            </div>
                        </div>
                        <div class="center">
                            <!-- <template v-if="loginType == 1">
                <div class="item account">
                  <span style="color: #BBB;font-size: 19px;padding-top: 7px" class="icon iconfont icon-wode"></span>
                  <input type="text" v-model="name" :placeholder="L['请输入账号/手机号']" class="input" />
                  <div data-type="userName" class="cancel" @click="clearInputVal('name')">
                    <span style="color: #bbb;" class="iconfont icon-cuowu"></span>
                  </div>
                </div>
                <div class="item password">
                  <span style="color: #BBB;font-size: 21px;padding-top: 7px" class="icon iconfont icon-mima1"></span>
                  <input type="password" v-model="password" :placeholder="L['请输入密码']" class="input" />
                  <div data-type="password" class="cancel" @click="clearInputVal('password')">
                    <span style="color: #bbb" class="iconfont icon-cuowu"></span>
                  </div>
                </div>
              </template> -->
                            <template v-if="loginType == 2">
                                <!-- <div class="item account" v-if="loginTypeo==2">
                  <span style="color: #BBB;font-size: 21px;padding-top: 7px" class="icon">
                    <img src="@/assets/building.png" alt="" style="width: 18px;height: 18px;">
                  </span>
                  <input type="text" v-model="enterpriseName" placeholder="请输入企业名称" class="input" />
                  <div data-type="enterpriseName" class="cancel" @click="clearInputVal('enterpriseName')">
                    <span style="color: #bbb;" class="iconfont icon-cuowu"></span>
                  </div>
                </div> -->
                                <div class="item account">
                                    <span
                                        style="color: #bbb; font-size: 21px; padding-top: 7px"
                                        class="icon iconfont icon-shouji2"
                                    ></span>
                                    <input type="text" v-model="name" :placeholder="L['请输入手机号']" class="input" />
                                    <div data-type="userName" class="cancel" @click="clearInputVal('name')">
                                        <span style="color: #bbb" class="iconfont icon-cuowu"></span>
                                    </div>
                                </div>
                                <div class="item password">
                                    <span
                                        style="color: #bbb; font-size: 18px; padding-top: 8px"
                                        class="icon iconfont icon-yanzhengma2"
                                    ></span>
                                    <input
                                        type="text"
                                        v-model="password"
                                        :placeholder="L['请输入验证码']"
                                        class="input"
                                    />
                                    <a href="javascript:void(0);" class="send_code" @click="getSmsCode">{{
                                        countDownM ? countDownM + L["s后获取"] : L["获取验证码"]
                                    }}</a>
                                </div>
                                <div class="login_tips" v-if="loginFlag == 1">
                                    <img src="@/assets/sigh.png" alt="" class="sigh" />
                                    <p class="login_tips_p">请使用多蒙达系统帐号登录</p>
                                </div>
                            </template>
                            <div class="error">
                                <span
                                    v-if="errorMsg"
                                    style="color: #e1251b; font-size: 14px"
                                    class="iconfont icon-jubao"
                                ></span>
                                {{ errorMsg }}
                            </div>
                            <a href="javascript:void(0)" @click="login" class="login_btn">{{ L["登录"] }}</a>
                            <a
                                href="javascript:void(0)"
                                @click="flagLogin = true"
                                class="login_dmd"
                                v-if="loginFlag == 2"
                            >
                                <img src="@/assets/duomengde_login.png" alt="" />
                                <p>多蒙达用户登录</p>
                            </a>
                        </div>
                        <div class="agree_wrap">
                            <input type="checkbox" :class="{ checkbox: true, default: true, checked: agreeFlag }" />
                            <span class="agree_selected iconfont icon-finish" @click="agree" />
                            <span class="text">
                                我同意<router-link tag="a" class="agreement" :to="`/agreement`"
                                    >《用户注册协议》</router-link
                                ><router-link tag="a" class="agreement" :to="`/agreement?code=privacy_policy`"
                                    >《隐私政策》</router-link
                                >
                            </span>
                        </div>
                    </template>

                    <template v-else>
                        <div class="qrMain">
                            <div class="qrMain_code">
                                <div
                                    class="qrMain_code_bg"
                                    :style="`filter:blur(${
                                        QRout ? '1px' : '0'
                                    });background-image:url(${QRlogShow});background-size:112% 112%;background-position:center;`"
                                ></div>
                                <div v-if="QRout" class="qrMain_code_cover">
                                    <p>二维码已失效</p>
                                    <div @click="reCreateQRcode">点击刷新</div>
                                </div>
                                <div v-else-if="QRsuccess" class="qrMain_code_cover">
                                    <p class="qrMain_code_cover_p">登录成功</p>
                                </div>
                            </div>
                            <p class="qrMain_title">打开手机商城，扫描二维码</p>
                            <div class="qrMain_tips">
                                <div class="qrMain_tips_item">
                                    <span
                                        style="color: #999999; font-size: 16px"
                                        class="icon iconfont icon-zhuce-mianshuru"
                                    ></span>
                                    <span>免输入</span>
                                </div>
                                <div class="qrMain_tips_item">
                                    <span
                                        style="color: #999999; font-size: 16px"
                                        class="icon iconfont icon-genganquan"
                                    ></span>
                                    <span>反应快</span>
                                </div>
                                <div class="qrMain_tips_item">
                                    <span
                                        style="color: #999999; font-size: 16px"
                                        class="icon iconfont icon-a-kuaijin1"
                                    ></span>
                                    <span>更安全</span>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div
                        :class="{
                            bottom: true,
                            flex_row_between_center: wxEnable == 1,
                            flex_row_end_center: wxEnable != 1 && loginFlag != 2,
                            bottom_padd: loginFlag == 2,
                            flex_row_between_center: loginFlag == 2,
                            flex_row_between_center: wxEnable == 1
                        }"
                    >
                        <!-- <img src="../../../assets/wechat_login.png" alt="" @click="wetChatLogin" v-if="wxEnable==1&&loginFlag==1"> -->
                        <div class="memb_login" v-if="loginFlag == 1" @click="go_dmd" style="margin-left: -16px">
                            普通登录
                        </div>

                        <div style="display: flex">
                            <a href="javascript:void(0)" @click="goToPage('/register')">{{ L["立即注册"] }}</a>
                            <!-- <router-link tag="a" :to="`/pwd/forget`">{{L['忘记密码']}}</router-link> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 弹框 -->
        <el-dialog v-model="flagLogin" width="460px" class="dialogs" :show-close="false" top="30vh">
            <div class="flagLogin_box flex_row_center_center">
                <img src="../../../assets/md_error.png" alt="" />
                是否允许授权多蒙达账号登录
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="go_dmd">确定</el-button>
                    <el-button @click="close" class="aft_btn">取消</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import { useRouter, onBeforeRouteLeave, useRoute } from "vue-router";
import { ref, getCurrentInstance, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import { platform } from "@/utils/config";
export default {
    name: "LoginIndex",
    setup() {
        const store = useStore();
        const router = useRouter();
        const route = useRoute();
        const { proxy } = getCurrentInstance();
        const L = proxy.$getCurLanguage();
        const isShow = ref(true);
        const flagLogin = ref(false); //显示确授权框
        const keyEnter = ref(true);
        const errorMsg = ref(); //错误提示
        const name = ref(""); //用户名
        const enterpriseName = ref(""); //企业名称
        const password = ref(""); //密码
        const loginType = ref(2); //登陆类型：1-账号密码登陆，2-手机验证码登陆
        const clickSmscode = ref(false); //是否获取验证码中
        const countDownM = ref(0); //短信验证码倒计时
        const timeOutId = ref(""); //定时器的返回值
        const agreeFlag = ref(false); //同意注册协议标识，默认不同意
        const configInfo = ref(store.state.configInfo);
        const gongcaiLogo = ref(require("../../../assets/gongcaiLogo.jpg"));
        const jicaiLogo = ref(require("../../../assets/jicaiLogo.jpg"));
        const gongcaiBg = ref(require("../../../assets/gongcaiBg.png"));
        const jicaiBg = ref(require("../../../assets/jicaiBg.png"));
        const defaultImg = ref('this.src="' + require("../../../assets/common_top_logo.png") + '"');
        const defaultBgImg = ref('this.src="' + require("../../../assets/login_bg.png") + '"');
        const fromurl = ref("");
        const wxEnable = ref("");
        const pcAppid = ref("");
        const loginFlag = ref("2"); //多蒙达和普通登录切换标识，1多蒙德2普通用户
        const loginTypeo = ref("1"); //个人登录和企业登录
        const shipperUserPhone = ref("");
        const companyName = ref("");
        const companyCode = ref("");

        /* 二维码登录 */
        const QRlogShow = ref("");
        const QRissend = ref(null); //二维码登录定时器
        const QRcheck = ref(null); //二维码循环定时器
        const QRsuccess = ref(false); //二维码登录成功
        const QRout = ref(false); //二维码是否过期
        const QRcodeLogin = ref(false); //二维码登录
        const qrCode = require("../../../assets/qrCode.png");
        const qrLogin = require("../../../assets/qrLogin.png");
        const idCode = require("../../../assets/idCode.png");
        const idLogin = require("../../../assets/idLogin.png");
        /* 二维码登录 */

        //由于这里的回车键触发事件和商品搜索框的回车键触发事件冲突，引入keyEnter变量判断
        document.onkeydown = function () {
            var key = window.event.keyCode;
            if (key == 13 && keyEnter.value) {
                login();
            }
        };
        // 多蒙达和普通登录切换
        const go_dmd = () => {
            if (loginFlag.value == 1) {
                loginFlag.value = 2;
            } else {
                loginFlag.value = 1;
            }
            flagLogin.value = false;
            errorMsg.value = "";
        };

        // 授权弹框关闭
        const close = () => {
            flagLogin.value = false;
        };
        const login = () => {
            let param = {};
            param.mobile = name.value;
            param.captcha = password.value;
            param.loginType = loginType.value;
            param.type = loginFlag.value;
            if (param.loginType == 1) {
                //账号、手机号的验证
                if (!param.mobile) {
                    errorMsg.value = L["请输入账号/手机号"];
                    return false;
                } else {
                    let checkName = proxy.$checkMemberName(param.mobile);
                    if (checkName !== true) {
                        //继续验证是否是手机号
                        let checkMobile = proxy.$checkPhone(param.mobile);
                        if (checkMobile !== true) {
                            errorMsg.value = L["请输入正确的账号/手机号"];
                            return false;
                        }
                    }
                }

                //密码校验
                if (!param.captcha) {
                    errorMsg.value = L["请输入密码"];
                    return false;
                } else {
                    let checkPwd = proxy.$checkPwd(param.captcha);
                    if (checkPwd !== true) {
                        errorMsg.value = checkPwd;
                        return false;
                    }
                }
            } else {
                if (!agreeFlag.value) {
                    errorMsg.value = "请同意用户注册协议及隐私政策";
                    return false;
                }
                //手机号验证
                if (!param.mobile) {
                    errorMsg.value = L["请输入手机号"];
                    return false;
                } else {
                    let checkMobile = proxy.$checkPhone(param.mobile);
                    // if (checkMobile !== true) {
                    //   errorMsg.value = checkMobile;
                    //   return false;
                    // }
                }

                //验证码校验
                if (!param.captcha) {
                    errorMsg.value = L["请输入短信验证码"];
                    return false;
                } else {
                    let checkSmsCode = proxy.$checkSmsCode(param.captcha);
                    if (checkSmsCode !== true) {
                        errorMsg.value = checkSmsCode;
                        return false;
                    }
                }
            }
            //如果未登录vuex中有购物车数据，需同步到该用户
            let cartInfo = [];
            // console.log(store.state);
            let cartListData = store.state.cartListData;
            if (JSON.stringify(cartListData) != "{}") {
                cartListData.cartGoods.map((promotItem) => {
                    promotItem.goodsCartGoodsRespList.map((cartItem) => {
                        cartInfo.push({
                            productId: cartItem.spuCode,
                            buyNum: cartItem.goodsCount
                        });
                    });
                });
            }
            // param.cartInfo = JSON.stringify(cartInfo);
            param.cartInfo = [];
            if (loginFlag.value == 2) {
                // 普通用户
                proxy.$post("api/system/common/mobile/login", param, "json").then((res) => {
                    if (res.code == 200) {
                        //将用户信息存缓存，并跳转到首页
                        //企业用户需要审核，普通用户和多蒙德不需要
                        if (res.data.memberType && res.data.memberType == "审核失败") {
                            router.push({
                                path: "/registrationReview",
                                query: {
                                    memberId: res.data.memberId,
                                    reason: res.data.reason
                                }
                            });
                        } else if (res.data.memberType && res.data.memberType == "审核中") {
                            ElMessage(res.data.memberType);
                        } else {
                            successlogin(res.data.accessToken, res.data.loginUser.uscUserId);
                        }
                    } else {
                        //提示错误
                        errorMsg.value = res.msg;
                    }
                });
            } else if (loginFlag.value == 1) {
                // 多蒙德用户
                let params = {
                    phone: name.value,
                    captcha: password.value
                };
                proxy.$post("api/shopp/member/dmdlogin", params).then((res) => {
                    if (res.code == 200) {
                        successlogin(res.data.accessToken, res.data.loginUser.uscUserId);
                    } else {
                        //提示错误
                        errorMsg.value = res.msg;
                    }
                });
            }
        };
        const successlogin = (token, uscUserId) => {
            localStorage.setItem("access_token", token);
            localStorage.setItem("refresh_token", token);
            localStorage.setItem("time", new Date().getTime().toString()); //存储refresh_token更新时间
            let par = { uscUserId: uscUserId };
            //获取用户信息，并同步信息到vuex
            proxy.$get("api/shopp/member/detail", par).then((rs) => {
                if (rs.code == 200) {
                    store.commit("updateMemberInfo", rs.data);
                    proxy.$getLoginCartListData(); //更新购物车数据
                    if (QRcodeLogin.value) {
                        clearTimeout(QRissend.value);
                        clearInterval(QRcheck.value);
                        QRissend.value = null;
                        QRcheck.value = null;
                    }
                    //   if (window.history.state.back) {
                    //     router.back();
                    //   } else {
                    router.replace({ path: "/member/index" });
                    //   }
                }
            });
        };
        //清空输入框内容
        const clearInputVal = (type) => {
            if (type == "name") {
                name.value = "";
            } else if (type == "password") {
                password.value = "";
            } else if (type == "enterpriseName") {
                enterpriseName.value = "";
            }
        };
        //登录方式切换
        const changeLoginType = (type) => {
            loginType.value = type;
            name.value = "";
            password.value = "";
            errorMsg.value = "";
        };
        //获取短信验证码
        const getSmsCode = () => {
            if (clickSmscode.value) {
                return;
            } else if (countDownM.value) {
                return false;
            }
            clickSmscode.value = true;
            let checkMobile = proxy.$checkPhone(name.value);
            if (checkMobile !== true) {
                errorMsg.value = checkMobile;
                clickSmscode.value = false;
            } else {
                let param = {};
                param.mobile = name.value;
                param.type = "free";
                proxy.$get("api/system/common/sendMobileValidCode", param).then((res) => {
                    if (res.code == 200) {
                        countDownM.value = 120;
                        countDown();
                        clickSmscode.value = false;
                    } else {
                        //提示错误
                        errorMsg.value = res.msg;
                        clickSmscode.value = false;
                    }
                });
            }
        };
        //倒计时
        const countDown = () => {
            console.log("000", countDownM.value);
            countDownM.value--;
            if (countDownM.value == 0) {
                clearTimeout(timeOutId.value);
            } else {
                timeOutId.value = setTimeout(countDown, 1000);
            }
        };
        //通过replace方式跳转页面
        const goToPage = (type) => {
            router.replace({
                path: type
            });
        };

        // 微信二维码登录
        const wetChatLogin = () => {
            let href = `https://open.weixin.qq.com/connect/qrconnect?appid=${
                pcAppid.value
            }&redirect_uri=${encodeURIComponent(
                fromurl.value
            )}&response_type=code&scope=snsapi_login&state=2or3m#wechat_redirect`;
            window.open(href, "_blank");
        };

        const getWxEnbale = () => {
            proxy
                .$get("api/shopp/setting/getSettings", { names: "login_wx_pc_is_enable,login_wx_pc_appid" })
                .then((res) => {
                    if (res.state == 200) {
                        wxEnable.value = res.data[0];
                        pcAppid.value = res.data[1];
                    }
                });
        };

        // 切换账号密码/二维码登录
        const changeQrlog = () => {
            QRcodeLogin.value = !QRcodeLogin.value;
            if (QRcodeLogin.value && !QRissend.value) {
                createQRcode();
            }
        };

        // 刷新二维码
        const reCreateQRcode = () => {
            setTimeout(() => {
                QRout.value = false;
                createQRcode();
            }, 200);
        };

        // 生成二维码 (过期定时器、获取扫码状态定时器)
        const createQRcode = () => {
            proxy.$get("v3/member/front/qr/login/createQr").then((res) => {
                /* 二维码图片 */
                QRlogShow.value = "data:image/png;base64," + res.data.image;
                // console.log(QRlogShow.value)
                /* 过期定时器 */
                QRissend.value = setTimeout(() => {
                    console.log("-----二维码过期-----");
                    clearTimeout(QRissend.value);
                    clearInterval(QRcheck.value);
                    QRissend.value = null;
                    QRcheck.value = null;
                    QRout.value = true;
                }, 300000);
                /* ------ end ------ */

                let param = {
                    u: res.data.u
                };
                /* 获取扫码状态定时器 */
                QRcheck.value = setInterval(() => {
                    proxy.$post("v3/member/front/qr/login/progress", param).then((result) => {
                        // console.log(result.data);
                        if (result.data.progress == 2) {
                            console.log("-----授权登录成功-----");
                            QRsuccess.value = true;
                            clearTimeout(QRissend.value);
                            clearInterval(QRcheck.value);
                            QRissend.value = null;
                            QRcheck.value = true;

                            localStorage.setItem("refresh_token", result.data.refreshToken);
                            /* 更新 access_token */
                            let params = new FormData();
                            params.append("grant_type", "refresh_token");
                            params.append("refresh_token", result.data.refreshToken);
                            proxy
                                .$post("v3/frontLogin/oauth/token", params, {
                                    headers: {
                                        "Content-Type": "multipart/form-data",
                                        Authorization: "Basic ZnJvbnQ6ZnJvbnQ="
                                    }
                                })
                                .then(
                                    (response) => {
                                        if (response.state == 200) {
                                            localStorage.setItem("access_token", response.data.access_token);
                                            localStorage.setItem("time", new Date().getTime().toString()); //存储refresh_token更新时间

                                            /* 获取用户信息，并同步信息到vuex */
                                            proxy
                                                .$get("api/shopp/member/detail", {
                                                    uscUserId: store.state.memberInfo.uscUserId
                                                })
                                                .then((res_info) => {
                                                    if (res_info.code == 200) {
                                                        store.commit("updateMemberInfo", res_info.data);
                                                        proxy.$getLoginCartListData(); //更新购物车数据
                                                        if (window.history.state.back) {
                                                            router.back();
                                                        } else {
                                                            router.replace({ path: "/member/index" });
                                                        }
                                                    }
                                                });
                                            /* ------ end ------ */
                                        }
                                    },
                                    (err) => {
                                        console.log("更新access_token出错：", err);
                                    }
                                );
                            /* ------ end ------ */
                        }
                    });
                }, 3000);
                /* ------ end ------ */
            });
        };

        watch([name, password], () => {
            if (loginType.value == 1) {
                password.value = password.value.substring(0, 20);
                name.value = name.value.substring(0, 20);
            } else {
                password.value = password.value.substring(0, 6);
                name.value = name.value.substring(0, 11);
            }

            if (password.value || name.value) {
                errorMsg.value = "";
            }
        });
        //是否同意用户注册协议
        const agree = () => {
            // console.log(agreeFlag.value);
            agreeFlag.value = !agreeFlag.value;
        };
        // 个人和企业切换
        const login_typeo = (num) => {
            loginTypeo.value = num;
        };

        // 单点登录
        const getLogin_o = () => {
            let param = {};
            param.shipperUserPhone = shipperUserPhone.value;
            if (companyName.value) {
                param.companyName = companyName.value;
            }
            if (companyCode.value) {
                param.companyCode = companyCode.value;
            }
            proxy.$post("v3/frontLogin/oauth/accessToken", param).then((res) => {
                if (res.state == 200) {
                    //将用户信息存缓存，并跳转到首页
                    localStorage.setItem("access_token", res.data.access_token);
                    localStorage.setItem("refresh_token", res.data.refresh_token);
                    localStorage.setItem("time", new Date().getTime().toString()); //存储refresh_token更新时间
                    //获取用户信息，并同步信息到vuex
                    proxy
                        .$get("api/shopp/member/detail", { uscUserId: store.state.memberInfo.uscUserId })
                        .then((res) => {
                            if (res.code == 200) {
                                store.commit("updateMemberInfo", res.data);
                                proxy.$getLoginCartListData(); //更新购物车数据
                                if (QRcodeLogin.value) {
                                    clearTimeout(QRissend.value);
                                    clearInterval(QRcheck.value);
                                    QRissend.value = null;
                                    QRcheck.value = null;
                                }
                                if (window.history.state.back) {
                                    router.back();
                                } else {
                                    router.replace({ path: "/member/index" });
                                }
                            }
                        });
                    countDownM.value = 120;
                    countDown();
                } else {
                    //提示错误
                    errorMsg.value = res.msg;
                }
            });
        };
        onMounted(() => {
            isShow.value = true;
            getWxEnbale();
            if (route.query.redirectUrl) {
                fromurl.value = window.location.origin + decodeURIComponent(route.query.redirectUrl);
            } else {
                fromurl.value = window.location.origin;
            }

            if (route.query.error) {
                ElMessage.error(decodeURIComponent(route.query.error));
            }
            // if(route.query.shipperUserPhone){   //2024/10/12 暂时注释
            //   isShow.value = false;
            //   shipperUserPhone.value = route.query.shipperUserPhone
            //   if(route.query.companyName){
            //     companyName.value = route.query.companyName
            //   }
            //   if(route.query.companyCode){
            // 		  companyCode.value = route.query.companyCode
            // 	  }
            //   getLogin_o()
            // }
        });

        onBeforeRouteLeave(() => {
            keyEnter.value = false;
        });

        return {
            L,
            agree,
            agreeFlag,
            loginType,
            login,
            name,
            password,
            clearInputVal,
            errorMsg,
            changeLoginType,
            getSmsCode,
            clickSmscode,
            countDownM,
            goToPage,
            configInfo,
            gongcaiLogo,
            jicaiLogo,
            platform,
            defaultImg,
            defaultBgImg,
            gongcaiBg,
            jicaiBg,
            wetChatLogin,
            wxEnable,
            QRcodeLogin,
            QRissend,
            QRout,
            qrCode,
            qrLogin,
            idCode,
            idLogin,
            changeQrlog,
            reCreateQRcode,
            createQRcode,
            QRlogShow,
            QRcheck,
            QRsuccess,
            go_dmd,
            loginFlag,
            flagLogin,
            close,
            loginTypeo,
            login_typeo,
            enterpriseName,
            shipperUserPhone,
            companyName,
            companyCode,
            isShow
        };
    }
};
</script>
<style lang="scss" scoped>
@import "../../../style/login.scss";
</style>
<style lang="scss">
.sld_login {
    .el-dialog__header {
        background-color: #fff;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .el-dialog__title {
            color: #fff;
            font-size: 14px;
        }
        .el-dialog__headerbtn {
            top: auto;
        }
        .el-dialog__headerbtn .el-dialog__close {
            color: #fff !important;
            font-size: 18px;
        }
    }
    .el-dialog {
        overflow: hidden;
        border-radius: 4px;
    }
    .el-dialog__footer {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .aft_btn {
        margin-left: 49px;
    }
    .el-button {
        width: 100px;
    }
    .aft_btn:hover {
        background-color: rgba($color: $colorMain, $alpha: 0.2);
        color: #f30213;
    }
    .aft_btn:focus {
        background-color: #fff;
        color: #333333;
        border-color: #dcdfe6;
    }
    .flagLogin_box {
        text-align: center;
        padding-top: 30px;
        img {
            width: 24px;
            height: 24px;
            margin-right: 10px;
        }
    }
}
.agree_wrap {
    margin-bottom: 11px;
    height: 14px;
    line-height: 14px;
    color: #999;
    padding-left: 26px;
    position: relative;
    cursor: pointer;
    .agree_selected {
        color: #fff;
        position: absolute;
        top: 0px;
        left: 26px;
        z-index: 2;
        font-size: 13px;
    }

    .checkbox {
        width: 14px;
        height: 14px;
        display: inline-block;
        vertical-align: top;
        position: relative;
        outline: none;
        -webkit-appearance: none;
        background: none;
        border: none;
        box-sizing: border-box;
        cursor: pointer;
        box-shadow: none;

        &.checked {
            &:before {
                background: $colorMain;
                border-color: $colorMain;
            }
        }

        &:before {
            border: 1px solid #ddd;
            background: #fff;
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            content: " ";
            display: block;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
        }
    }

    .text {
        margin-left: 5px;
        display: inline-block;
        vertical-align: top;

        .agreement {
            color: #000;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
</style>
