<template>
  <div class="sld_settle_results">
    <div class="sld_results">
      <img src="../../../assets/successfully.png" alt="" class="fully" v-if="false">
      <img src="../../../assets/entry.png" alt="" class="fully" v-if="true">
      <p class="fully_jie" v-if="state==4">入驻成功</p>
      <p class="fully_jie" v-if="state==1">审核中</p>
      <p class="fully_jie" v-if="state==3">审核失败</p>
      <p class="fully_jie" v-if="state==2">审核通过</p>
      <div class="fully_bor" v-if="state==4">
        <p class="fully_bor_one">供应商登录请访问下面地址,用会员账号密码登录后开始使用</p>
        <p class="fully_bor_two">{{seller_url}}</p>
      </div>
       <div class="fully_entry" v-if="state==3" style="flex-direction: column;">
       <div class="fully_entry_box">
         <p class="fully_entry_one">失败原因:</p>
         <p class="fully_entry_two">{{refuseReason}}</p>
       </div>
       <div class="fully_entry_box" style="margin-top: 10px;">
         <p class="fully_entry_one" style="text-align: right;padding-right: 6px;">备注:</p>
         <p class="fully_entry_two">{{auditInfo}}</p>
       </div>
      </div>
       <div class="fully_entry" v-if="state==1">
       <div class="fully_entry_box">
         <p class="fully_entry_two" style="text-align: center;">{{stateValue}}</p>
       </div>
      </div>
      <div class="fully_btn" v-if="state==4" @click="goseller">
        前往
      </div>
      <div class="fully_btn" v-if="state==3" @click="again">
        重新申请
      </div>
      <div class="fully_btn" v-if="state==1" @click="goIndex">
        返回
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, watchEffect, ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import { ElMessage } from 'element-plus';
import SldCommonEmpty from '@/components/SldCommonEmpty'
export default {
  name: "AccountList",
  components: { SldCommonEmpty },
  setup() {
    const router = useRouter();
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const seller_url = ref('')
    const nextStep =()=>{
      router.push({
          path: '/member/settle/enterprise',
      });
    }
    const again  =()=>{
       router.push({
          path: '/member/settle/agreement',
          query:{
            type:2
          }
      });
    }
    const state = ref('')
    const stateValue = ref('')
    const refuseReason = ref('')
    const auditInfo = ref('')
     //获取入驻详情接口
     const getDetail = ()=>{
      proxy.$get('v3/member/front/member/applyDetail').then(res => {
            if (res.state == 200) {
              seller_url.value = res.data.url
              state.value = res.data.state
              stateValue.value = res.data.stateValue
              if(res.data.state==3){
                refuseReason.value = res.data.refuseReason
                auditInfo.value = res.data.auditInfo
              }
            }
        })
    }

    // 
    const goIndex = ()=>{
      router.replace('/member/index')
    }
    const goseller = ()=>{
      window.open(seller_url.value, "_blank");
    }
    onMounted(()=>{
      getDetail()
    })

    return {
      L,
      nextStep,
      again,
      goseller,
      state,
      stateValue,
      refuseReason,
      goIndex,
      auditInfo,
      seller_url
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../style/member/results.scss";
</style>
<style lang="scss">

</style>
