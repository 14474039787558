// 售后详情页面
<template>
    <div class="sld_refund_detail" v-if="refundDetail.data">
        <!-- 退款进度 start -->
        <div class="refund_detail_top flex_row_center_center"
            v-if="returnLogList.data && returnLogList.data.length > 0">
            <div class="refund_detail_status">
                <p class="detail_ordersn">换货订单号:{{refundDetail.data.afsSn}}</p>
                <p class="detail_top_status">{{refundDetail.data.stateValue}}</p>
                <p class="status_option"
                    v-if="(refundDetail.data.afsType == 1 || refundDetail.data.afsType == 3) && refundDetail.data.state == 202">
                    <div @click="reApplyReFund">重新申请</div>
                </p>
            </div>
            <div class="refund_speed">
                <!--
                    退货退款状态：100-买家申请仅退款；101-买家申请退货退款；102-买家退货给商家；200-商家同意退款申请；201-商家同意退货退款申请；202-商家拒绝退款申请(退款关闭/拒收关闭)；203-商家确认收货；300-平台确认退款(已完成)
                    afsType 售后服务端类型，1-退货退款单（需关联处理退款金额），2-换货单，3-仅退款单
                -->
                <div class="refund_speed_con flex_row_center_start">
                    <div class="refund_speed_pre flex_column_center_center">
                        <img src="@/assets/order/htscit1.png" alt="">
                        <div class="refund_no_box refund_noactive">{{L['提交申请']}}</div>
                    </div>
                    <div class="refund_speed_line">
                        <img src="@/assets/order/to_line.png" alt="">
                    </div>
                    <div class="refund_speed_pre flex_column_center_center">
                        <img src="@/assets/order/htscit2_b.png" alt="" v-if="refundDetail.data.state ==100">
                        <img src="@/assets/order/htscit2.png" alt="" v-else>
                        <div
                            class="refund_noactive refund_no_box">商家审核</div>
                    </div>
                    <div class="refund_speed_line">
                        <img src="@/assets/order/to_line_b.png" alt=""
                            v-if="refundDetail.data.state ==100">
                        <img src="@/assets/order/to_line.png" alt="" v-else>
                    </div>
                    <div class="refund_speed_pre flex_column_center_center">
                        <img src="@/assets/order/htscit3_b.png" alt=""
                            v-if="refundDetail.data.state == 100 || refundDetail.data.state == 200 || refundDetail.data.state == 201">
                        <img src="@/assets/order/htscit3.png" alt="" v-else>
                        <div
                             class="refund_noactive refund_no_box">
                            <p>买家发货</p>
                            <p v-if="refundDetail.data.state != 100 && refundDetail.data.state != 200 && refundDetail.data.state != 201">物流公司:{{refundDetail.data.buyerExpressName}}</p>
                            <p v-if="refundDetail.data.state != 100 && refundDetail.data.state != 200 && refundDetail.data.state != 201">物流单号:{{refundDetail.data.buyerExpressNumber}}</p>
                        </div>
                    </div>
                    <div class="refund_speed_line">
                        <img src="@/assets/order/to_line_b.png" alt=""
                             v-if="refundDetail.data.state == 100 || refundDetail.data.state == 200 || refundDetail.data.state == 201">
                        <img src="@/assets/order/to_line.png" alt="" v-else>
                    </div>
                    <div class="refund_speed_pre flex_column_center_center">
                        <img src="@/assets/order/htscit4_b.png" alt=""
                            v-if="refundDetail.data.state <202">
                        <img src="@/assets/order/htscit4.png" alt="" v-else>
                        <div
                            class="refund_noactive refund_no_box">商家确认收货</div>
                    </div>
                    <div class="refund_speed_line">
                        <img src="@/assets/order/to_line_b.png" alt=""
                            v-if="refundDetail.data.state <202">
                        <img src="@/assets/order/to_line.png" alt="" v-else>
                    </div>
                    <div class="refund_speed_pre flex_column_center_center">
                        <img src="@/assets/order/htscit5_b.png" alt=""
                            v-if="refundDetail.data.state <204">
                        <img src="@/assets/order/htscit5.png" alt="" v-else>
                        <div
                            class="refund_noactive refund_no_box">
                            <p>商家发货</p>
                            <p v-if="refundDetail.data.state == 204 || refundDetail.data.state == 301">物流公司:{{refundDetail.data.storeExpressName}}</p>
                            <p v-if="refundDetail.data.state == 204 || refundDetail.data.state == 301">物流单号:{{refundDetail.data.storeExpressName!='商家自行配送'?refundDetail.data.storeExpressNumber:'无'}}</p>
                        </div>
                    </div>
                    <div class="refund_speed_line">
                        <img src="@/assets/order/to_line_b.png" alt=""
                            v-if="refundDetail.data.state != 204 && refundDetail.data.state != 301">
                        <img src="@/assets/order/to_line.png" alt="" v-else>
                    </div>
                    <div class="refund_speed_pre flex_column_center_center">
                        <img src="@/assets/order/htscit6_b.png" alt=""
                            v-if="refundDetail.data.state != 301">
                        <img src="@/assets/order/htscit6.png" alt="" v-else>
                        <div
                            class="refund_noactive refund_no_box">{{L['完成']}}</div>
                    </div>
                    <div class="refund_success_fail"
                        v-if="refundDetail.data.state == 200 || refundDetail.data.state == 301 || refundDetail.data.state == 203">
                        <img src="@/assets/order/t_fail.png" alt="" v-if="refundDetail.data.state == 200||refundDetail.data.state == 203">
                        <img src="@/assets/order/t_success.png" alt="" v-if="refundDetail.data.state == 301">
                    </div>
                </div>
            </div>
        </div>
        <!-- 退款进度 end -->
        <!-- 退款日志 start -->
        <div class="refund_journal flex_row_start_start">
            <div class="refund_goods flex_column_start_center">
                <div class="refund_goods_img" :style="{backgroundImage:'url(' + refundDetail.data.productImage +')'}">
                </div>
                <p class="refund_goods_name">{{refundDetail.data.goodsName}}</p>
                <p class="refund_goods_specvalue">{{refundDetail.data.specValues}}</p>
                <p class="refund_goods_price">￥{{refundDetail.data.productPrice}}</p>
            </div>
            <div class="refund_journal_con">
                <div class="refund_journal_pre flex_row_start_start"
                    v-for="(logItem,logIndex) in returnLogList.data" :key="logIndex">
                    <div class="journal_left flex_column_start_center">
                        <span></span>
                        <span :class="{active:logItem.state==101||logItem.state==204||logItem.state==100}"></span>
                    </div>
                    <div class="journal_right">
                        <div class="journal_des">{{logItem.content}}</div>
                        <div class="journal_time" v-if="logItem.state==100">
                            换货数量:{{refundDetail.data.replacementNum}}
                        </div>
                        <div class="journal_time" v-if="logItem.state==100" style="width: 182px;">
                           换货原因:{{refundDetail.data.applyReasonContent}}
                        </div>
                        <div class="journal_time" v-if="logItem.state==100&&refundDetail.data.afsDescription" style="width: 182px;">
                           问题描述:{{refundDetail.data.afsDescription}}
                        </div>
                        <div class="journal_time">{{L['时间']}}：{{logItem.createTime}}</div>
                        <div class="journal_time" v-if="logItem.state==101">
                           物流公司:{{refundDetail.data.buyerExpressName}}
                        </div>
                        <div class="journal_time" v-if="logItem.state==101">
                           物流单号:{{refundDetail.data.buyerExpressNumber}}
                        </div>
                        <div class="journal_time" v-if="logItem.state==204">
                           物流公司:{{refundDetail.data.storeExpressName}}
                        </div>
                        <div class="journal_time" v-if="logItem.state==204">
                           物流单号:{{refundDetail.data.storeExpressName!='商家自行配送'?refundDetail.data.storeExpressNumber:'无'}}
                        </div>
                        <div class="journal_time refund_voucher flex_row_start_start"
                            v-show="refundDetail.data.applyImageList && refundDetail.data.applyImageList.length > 0&&logItem.state==100">
                            <div class="refund_voucher_list flex_row_start_start" v-for="(applyImgItem,applyImgIndex) in refundDetail.data.applyImageList" :key="applyImgIndex">
                                <div class="refund_voucher_pre"
                                   >
                                    <el-image :src="applyImgItem" :preview-src-list="refundDetail.data.applyImageList"
                                        fit="cover"></el-image>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 退款日志 end -->
    </div>
</template>

<script>
    import { reactive, getCurrentInstance, ref, onMounted } from "vue";
    import { ElMessage } from "element-plus";
    import { useRoute, useRouter } from "vue-router";

    export default {
        setup() {
            const { proxy } = getCurrentInstance();
            const L = proxy.$getCurLanguage();
            const route = useRoute();
            const orderState = ref(''); //订单状态
            const afsSn = ref(''); //售后单号
            const orderId = ref(''); //售后单号
            const refundDetail = reactive({ data: {} }); //售后相信
            const sourceType = ref(1); //仅退款 ；退款退货的来源类型 1：仅退款 2：退款退货
            const router = useRouter()
            const returnLogList = reactive({ data: [] }); //售后相信
            onMounted(() => {
                orderState.value = route.query.orderState;
                afsSn.value = route.query.afsSn;
                orderId.value = route.query.orderId;
                sourceType.value = route.query.curTitleId;
                getRefundOrderDetail();
            });
            //获取售后订单详情
            const getRefundOrderDetail = () => {
                let params = {
                    afsSn: afsSn.value,
                    orderId: orderId.value
                }
                proxy.$get('v3/business/front/after/sale/replacementDetail', params).then((res) => {
                    if (res.state == 200) {
                        refundDetail.data = res.data[0];
                        // returnLogList.data = returnLogList.data.reverse();
                        res.data.map(i=>{
                            returnLogList.data  = returnLogList.data.concat(i.replacementLogList)
                        })
                        
                        returnLogList.data.map((logItem, logIndex) => {
                            if (logItem.state==100) {
                                logItem.applyImageList = refundDetail.data.applyImageList; //退款凭证
                                // logItem.applyNum = refundDetail.data.returnNum;
                                // logItem.returnMoneyAmount = refundDetail.data.returnMoneyAmount;
                                // logItem.afsDescription = refundDetail.data.afsDescription;
                                // logItem.applyReasonContent = refundDetail.data.applyReasonContent;
                            }
                            if(logItem.state==100){

                            }
                            if (logIndex == 0) {
                                logItem.platformRemark = refundDetail.data.platformRemark;
                            }
                        })
                    } else {
                        ElMessage.error(res.data)
                    }
                })
            }

            const reApplyReFund = () => {
                let { orderSn, orderProductId } = refundDetail.data
                proxy.$get('v3/business/front/after/sale/apply/applyInfo', {
                    orderSn,
                    orderProductId
                }).then(res => {
                    if (res.state == 200) {
                        router.push({
                            path: "/member/order/refund/apply",
                            query: {
                                orderState: orderState.value,
                                orderProductId
                            }
                        });
                    } else {
                        ElMessage(res.msg)
                    }
                })
            }

            return {
                L,
                orderState,
                afsSn,
                refundDetail,
                sourceType,
                reApplyReFund,
                returnLogList
            };
        },
    };
</script>

<style lang="scss" scoped>
    @import "../../../style/base.scss";
    @import "../../../style/iconfont.css";
    @import "../../../style/exchangeDetail.scss";

    .el-image {
        width: 50px;
        height: 50px;
    }

    .refund_remark {
        word-break: break-all;
    }
</style>